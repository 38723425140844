import React from 'react'
// import Hero from '../components/hero'
import Header from '../components/header'
// import About from '../components/about'
// import Course from '../components/courses'
// import Modelcarousel from '../components/carousel'
import Footer1 from '../components/footer'
import ContactForm from '../components/contactform'

export default function Contact() {
  return (
    <>
     <Header />
     
     <ContactForm />
      <Footer1/>
    </>
  )
}
