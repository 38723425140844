import React from 'react'
// import Hero from '../components/hero'
import Header from '../components/header'
// import About from '../components/about'
// import Course from '../components/courses'
// import Modelcarousel from '../components/carousel'
import Footer1 from '../components/footer'
import Tr1 from '../components/tr1'
import Tr2 from '../components/tr2'
import Tr3 from '../components/tr3'
import Trdesigner from '../components/trdesigner'
// import Tr4 from '../components/tr4'

export default function Tarun() {
  return (
    <>
     <Header />
      {/* <Hero /> */}
      <Tr1/>
      <Tr2/>
      <Tr3/>
      <Trdesigner />
      {/* <Tr4/> */}
      
     
      <Footer1/>
    </>
  )
}
